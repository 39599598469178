import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import startsWith from 'lodash/startsWith';
import { CardWide } from '../common/blocks/CardWide';
import { Main } from '../common/blocks/Main';
import JourneySearch from './journey/JourneySearch';
import {
	actionCreators as productActions,
	TAG_PREFIXES,
	PRODUCT_TYPES,
	getProductSetTags,
	getTagByNamePrefix,
	TAGS,
	compareAddonsByValueType,
	isProductGroupable,
} from '../../reducer/products';
import { actionCreators as journeyActions } from '../../reducer/journey';
import { actionCreators as cartActions } from '../../reducer/cart';
import { TicketTypeSelector } from '../../components/common/blocks/TicketTypeSelector';
import { TravellerTypeSelector } from '../../components/common/blocks/TravellerTypeSelector';
import { MultipleTravellerTypeSelector } from '../common/blocks/MultipleTravellerTypeSelector';
import SelectedProduct from './SelectedProduct';
import {
	getProductSets,
	hasEmptyProductResponse,
	hasGetProductsError,
} from '../../reducer';
import { Info } from './../common/elements/Info';
import { Error } from './../common/elements/Error';
import { PRIVATE } from '../../Paths';
import RebuyTickets from './RebuyTickets';
import withTextContext from '../../utils/withTextContext';
import PurchaseNavigation from './purchase/PurchaseNavigation';
import { ProductTypeSelector } from './purchase/ProductTypeSelector';
import { unique } from '../../utils/helperFunctions';
import { Banner } from '../common/blocks/Banner';


export class BuyTicketPage extends Component {
	state = {
		productType: PRODUCT_TYPES.SINGLE,
		ticketType: {},
		travellerTypes: [],
	};

	constructor(props) {
		super(props);
		this.productArea = React.createRef();
	}

	componentDidMount() {
		this.resetState();
		document.title = "Sök ny resa | Mitt konto"
		this.unlisten = this.props.history.listen(location => {
			if (location.pathname === PRIVATE.BUY_TICKET) {
				this.resetState();
			}
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}

	resetState = cb => {
		this.props.removeProducts();
		this.props.removeSelectedProductSet();
		this.props.removeJourneys();
		this.props.resetCartError();
		this.resetComponentState(cb);
	};

	resetComponentState = cb =>
		this.setState({ ticketType: {}, travellerTypes: [] }, cb);

	getProducts = async currentJourney => {
		await this.props.getProducts(
			currentJourney.resultKey,
			currentJourney.sequenceNumber
		);

		if (!this.props.hasGetProductsError) {
			this.updateSelectedProductSet();
		}

		this.productArea.current.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	};

	onSelectTicket = selectedTicketType => {
		const { ticketType } = this.state;

		if (isEqual(selectedTicketType, ticketType)) {
			return;
		}

		this.setState({ ticketType: selectedTicketType }, () =>
			this.updateSelectedProductSet()
		);
	};

	onSelectTraveller = selectedTravellerTypes => {
		const { ticketType, travellerTypes } = this.state;

		if (isEqual(selectedTravellerTypes, travellerTypes)) {
			return;
		}

		if (!ticketType) {
			return;
		}

		this.setState({ travellerTypes: selectedTravellerTypes }, () =>
			this.updateSelectedProductSet()
		);
	};

	updateSelectedProductSet = () => {
		const { products } = this.props;
		const { ticketType, travellerTypes } = this.state;

		if (isEmpty(ticketType) || isEmpty(travellerTypes) || isEmpty(products))
			return;

		const setId = ticketType.id;
		const productSetConfiguration = {
			product: ticketType.name,
			travellers: this.getTagsWithCount(travellerTypes, TAG_PREFIXES.TRAVELLER),
			addonTags: this.getTagsWithCount(travellerTypes, TAG_PREFIXES.ADDON),
		};

		this.props.createProductSet(setId, productSetConfiguration);
	};

	getTagsWithCount = (travellerTypes, prefix) =>
		travellerTypes
			.filter(t => startsWith(t.name, prefix))
			.map(t => `${t.name}*${t.count}`);

	handleProductChange = e => {
		this.setState({ productType: e.target.value });
	};

	setProductType = productType =>
		this.setState({ productType, ticketType: {}, travellerTypes: [] });

	getFilteredTicketTypes = () => {
		const { productType } = this.state;
		const { products = [] } = this.props;

		const filteredProducts = products.filter(t => t.tags.includes(productType));
		const uniqueProducts = this.getUniqueProductSetListByProductTag(
			filteredProducts
		);
		return uniqueProducts;
	};

	getTravellerTagsForSelectedProduct = () => {
		const { ticketType } = this.state;
		const { productSetTags = [], products = [] } = this.props;

		const relevantProducts = products.reduce(
			(list, product) =>
				this.compareProductsByProductTag(product, ticketType)
					? [...list, product]
					: list,
			[]
		);

		const relevantProductTags = relevantProducts.reduce(
			(o, product) => unique([...o, ...product.tags]),
			[]
		);

		let productsTags = productSetTags.filter(
			tag =>
				tag.type === TAGS.TRAVELLER &&
				(relevantProductTags || []).includes(tag.name)
		);

		return productsTags.sort((a, b) => a.name.localeCompare(b.name));
	};

	getUniqueProductSetListByProductTag = products =>
		products.reduce(
			(productSets, product) =>
				productSets.find(o => this.compareProductsByProductTag(o, product))
					? productSets
					: [...productSets, product],
			[]
		);

	compareProductsByProductTag = (productA, productB) =>
		isEqual(
			getTagByNamePrefix(productA, TAG_PREFIXES.PRODUCT),
			getTagByNamePrefix(productB, TAG_PREFIXES.PRODUCT)
		);

	getAvailableAddonTags = () => {
		const { ticketType } = this.state;
		const { productSetTags = [] } = this.props;

		return productSetTags.filter(
			tag =>
				tag.name.includes(TAG_PREFIXES.ADDON) &&
				(ticketType.available_addon_tags || [])
					.map(t => t.name)
					.includes(tag.name)
		);
	};

	render() {
		const { travellerTypes: currentTravellerTypes, ticketType } = this.state;
		const { hasEmptyProductResponse, hasGetProductsError, texts } = this.props;

		const ticketTypes = this.getFilteredTicketTypes();
		const travellerTypes = this.getTravellerTagsForSelectedProduct();

		const addonTypes = this.getAvailableAddonTags();
		let sortedAddonTypes = addonTypes.sort(compareAddonsByValueType);

		const allowMultipleTravellers = isProductGroupable(ticketType);

		return (
			<BuyTicketContainer>
				<RebuyTickets />
				<Banner bannerImg={texts.private.images.banners.buyticketpage} />
				<main>
				<Main>
					
					<>
						<PurchaseNavigation
							texts={texts}
						/>
						<TicketBox id="buyTicketContainer">
							<H1>Sök ny resa</H1>
							<ProductTypeSelector onSelectProductType={this.setProductType} />

							<JourneySearch onSelectJourney={this.getProducts} />

							<section ref={this.productArea}>
								{hasEmptyProductResponse && (
									<Info>
										Det finns inga tillgängliga produkter för denna avgång.
									</Info>
								)}

								{hasGetProductsError && <Error />}

								<TicketTypeSelector
									types={ticketTypes}
									onSelectTicket={this.onSelectTicket}
									texts={texts}
								/>

								{allowMultipleTravellers ? (
									<MultipleTravellerTypeSelector
										types={[...travellerTypes, ...sortedAddonTypes]}
										onSelectTraveller={this.onSelectTraveller}
										texts={texts}
									/>
								) : (
									<TravellerTypeSelector
										types={travellerTypes}
										currentTravellerType={currentTravellerTypes[0]}
										onSelectTraveller={this.onSelectTraveller}
										texts={texts}
									/>
								)}
							</section>
						</TicketBox>
					</>
				
				</Main>
				</main>
				<SelectedProduct />
			</BuyTicketContainer>
		);
	}
}

const BuyTicketContainer = styled.main`
	position: relative;
`;

const H1 = styled.h1`
	font-size: 30px;
	margin-bottom: 1em;
`;

const TicketBox = styled(CardWide)`
	margin-left: auto;
	margin-right: auto;
	display: block;
`;

export default withTextContext(
	connect(
		store => ({
			journey: store.journey,
			productSetTags: getProductSetTags(store),
			products: getProductSets(store),
			hasEmptyProductResponse: hasEmptyProductResponse(store),
			hasGetProductsError: hasGetProductsError(store),
		}),
		{
			...productActions,
			...journeyActions,
			...cartActions,
		}
	)(withRouter(BuyTicketPage))
);
